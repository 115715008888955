import { $, $$ } from './../helpers/qsa'

const showOrHide = (el, count) => {
    if (count > 0) {
        el.removeAttribute('hidden')
    } else {
        el.setAttribute('hidden', 'hidden')
    }
}

$$('.documents').forEach(container => {
    const filter = $('.documents__filter-list', container)
    const clearAction = $('.documents__filter-clear', container)
    const categories = $$('.documents__categories-item', container)

    // Track active tags
    let activeTags = []

    clearAction.addEventListener('click', e => {
        activeTags = []

        Array.from(filter.children).forEach(item => item.classList.remove('is-active'))

        categories.forEach(category => category.removeAttribute('hidden'))
        $$('[data-tags]', container).forEach(item => item.removeAttribute('hidden'))
    })

    filter.addEventListener('click', e => {
        if (!e.target.matches('li')) {
            return
        }

        // Get tag
        const tag = e.target.getAttribute('data-tag')

        // Add or remove?
        const tagIndex = activeTags.indexOf(tag)

        if (tagIndex > -1) {
            activeTags.splice(tagIndex, 1)
        } else {
            activeTags.push(tag)
        }

        // Update class
        e.target.classList.toggle('is-active', activeTags.includes(tag))

        if (activeTags.length === 0) {
            categories.forEach(category => category.removeAttribute('hidden'))
            $$('[data-tags]', container).forEach(item => item.removeAttribute('hidden'))

            return
        }

        // Filter, targeting categories first
        categories.forEach(category => {
            let count = 0

            $$('[data-tags]', category).forEach(item => {
                const tags = item.getAttribute('data-tags').split(',')
                const found = tags.filter(tag => activeTags.includes(tag))

                showOrHide(item, found.length)

                count += found.length
            })

            showOrHide(category, count)
        })
    })
})
